@import 'src/assets/styles/media';

$header-height: 48px;
$header-MobileHeight: 3.5rem;
$medqp-background: #ffc8a8;

.wrapperOuter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(var(--vh, 1vh) * 100 - 1px);
  // height: 100vh;
  // min-height: 700px;
  overflow: hidden;
  padding: 1rem 1.5rem;
  background-color: $medqp-background;
  background: url('../../assets/nImages/background_cover.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @include tablet-xl {
    // min-height: max-content;
    // height: 100%;
    overflow-y: auto;
    padding: 1rem;
  }

  @include mobile-xl {
    min-height: calc(var(--vh, 1vh) * 100 - 5px);
    padding: 0.5rem 1.15rem;
    justify-content: flex-start;
  }
}

.wrapperHeader {
  display: flex;
  align-items: center;
  height: $header-height;
  flex-shrink: 0;
  margin-bottom: 1rem;

  @include tablet-xxl {
    height: $header-MobileHeight;
    margin-bottom: 0.5rem;
  }
  @include mobile-xl {
    margin-bottom: 0rem;
  }
}

.wrapperInner {
  display: flex;
  height: calc(100% - #{$header-height} - 1rem);
  flex-grow: 1;

  @include tablet-xxl {
    flex-direction: column;
    height: calc(100% - 4.5rem);
  }

  @include mobile-xl {
    height: calc(100%);
    flex-grow: 0;
  }
}

.wrapperMenu {
  transition: 0.3s;
  width: auto;
  height: 100%;
  display: flex;
  flex: 0 0 auto;
  white-space: nowrap;

  @include tablet-xxl {
    flex: 1;
    white-space: pre-line;
    margin-bottom: 0rem;
    width: 100%;
    height: 100%;
  }

  @include mobile-xl {
    margin-bottom: 0rem;
    flex: 1;
    white-space: pre-line;
  }
}

.wrapper {
  height: 100%;
  flex: 1 1 auto;
  transition: 0.3s;
  display: flex;
  z-index: 0;

  @include tablet-xxl {
    width: 100%;
    min-height: calc(100% - 6.5rem);
    padding-bottom: 0.5rem;
  }

  @include mobile-xl {
    padding-bottom: 0;
    height: 100%;
  }
}

.wrapperRoutes {
  height: 100%;
  width: 100%;
}

.eventBackgroundContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  .event_background {
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
}