@import 'src/assets/styles/media';

.radiologyResults {
  padding: 0 1rem;
  height: 100%;

  @include tablet-xxl {
    padding-right: 0.69rem;
  }

  @include mobile-xl {
    padding: 0;
    width: 100%;
  }
}

.radiologyResults__image {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @include tablet-xl {
    padding-right: 0;
    flex-direction: row-reverse;
  }
}

.radiologyResults__pic {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @include tablet-min-xl {
    height: 3rem;
    padding: 2px;
  }

  @include desktop-min-l {
    height: 5rem;
    padding: 5px;
  }
}

.radiology__video {
  margin: 5px 0;
}

.radiologyResults__img {
  width: 100%;
  height: 100%;
  object-position: center;
  max-width: 120px;
  image-rendering: pixelated;
}

.radiologyResults__vieWText {
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  background-color: var(--color-lightOrange);
  border-radius: 5px;
  padding: 0 0.75rem;
  margin-left: 0.5rem;
  word-break: keep-all;

  @include tablet-xl {
    font-size: 1rem;
    margin-right: 0.77rem;
  }
}

.radiologyResults_table {
  height: 100%;
  overflow-y: auto;
}

.radiologyResults_body {
  height: calc(100% - 3.62rem);
  overflow-y: auto;
  padding-right: 1rem;

  @include mobile-xl {
    height: calc(100% - 2.5rem);
    padding-right: 0.5rem;
    padding-bottom: 1rem;
  }
}