@import '../../../assets/styles/media';
.checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.checkbox__label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox__inner {
  border: 1px solid var(--color-darkGreen);
  border-radius: 1px;
  width: 1.68rem;
  height: 1.68rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  @include mobile-xl{
    width: 1.35rem;
    height: 1.35rem;
  }

  &.checked{
    background-color: var(--color-darkGreen);
  }
}

.dropdownRow__noted_active{
  background: url('../../../assets/nImages/checkbox_selected.svg');
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.isDisabled {
   cursor: default;
  .checkbox__title{
   color: var(--color-gray10);
  }
  .checkbox__inner {
   border: 1px solid var(--color-gray10);
  }
}

.checkbox__checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  flex-shrink: 0;
}

.checkbox__input {
  display: none;

  &:checked + .checkbox__checkmark {
    opacity: 1;
  }
}

.checkbox__title {
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--color-fontBlack);
  @include mobile-xl{
    font-size: 0.85rem;
  }
}

.dropdownRow__checkbox {
  width: 1.68rem;
  height: 1.68rem;
  // border: 1px solid var(--color-darkGreen);
  border-radius: 1px;
  flex-shrink: 0;
  background-color: #969393;
  @include mobile-xl{
    width: 1.35rem;
    height: 1.35rem;
  }
}