@import 'src/assets/styles/media';

.videoPopup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.videoPopup__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85vw;
  height: 85vh;

  @include mobile-xl {
    height: 80vh;
  }
}

.videoPopup__pic {
  .video_holder {
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  max-width: 85%;
  // padding: 0.81rem;
  background-color: var(--color-white);
  border: 8px solid var(--color-darkGreen);
  border-radius: 0.75rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @include desktop-l {
    margin: 1.875rem;
    border: 6px solid var(--color-darkGreen);
  }
}

.videoPopup__img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
}

.videoPopupClose {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.videoPopup__close {
  position: absolute;
  top: -1.845rem;
  right: -1.845rem;
  padding: 0.81rem;
  width: 3.69rem;
  height: 3.69rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color-darkGreen);
  cursor: pointer;
  z-index: 15;

  @include desktop-l {
    top: -1.47rem;
    right: -1.47rem;
    width: 2.69rem;
    height: 2.69rem;
  }

  @include mobile-xl {
    top: -1.2rem;
    right: -1.3rem;
  }
}

.videoPopup__closeSvg {
  fill: var(--color-white);
  width: 100%;
  height: 100%;
  object-position: center;
}
