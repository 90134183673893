@import "src/assets/styles/media";

.table {}

.table__header {
  margin-bottom: 0.5rem;
  width: 100%;
  min-height: 3rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.375rem;
  @include mobile-xl{
    min-height: auto;
  }
}

.table__headerCell {
  padding-left: 1rem;
  padding-right: 0.8rem;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 1.15rem;
  font-weight: 700;
  @include mobile-xl{
    font-size: 0.85rem;
    padding-left: 0.5rem;
    padding-right: 0.25rem;
    height: 100%;
    justify-content: flex-end;
    word-break: break-word;
  }
}

.table__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.table__row {
  margin-bottom: 0.5rem;
  padding: 0;
  width: 100%;
  min-height: 25px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background-color: var(--color-gray5);
  color: var(--color-fontBlack);
}

.table__cell {
  padding-left: 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.69rem;
  color: var(--color-fontBlack);
  border-left: 2px solid var(--color-darkGreen);
  align-items: center;
  display: flex;
  word-break: break-word;

  @include mobile-xl{
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 700;
    padding: 0.25rem;
    padding-left: 0.5rem;
  }

  // @include tablet-xl {
  //   width: 100%;
  //   padding: 0.5rem;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   border-bottom: 1px solid var(--color-gray10);

  //   &:last-child {
  //     border-bottom: none;
  //   }
  // }
}

.table__title {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  padding-right: 4rem;
  max-width: 70%;
}

.table__info {
  // @include tablet-xl {
  //   text-align: end;
  //   display: flex;
  //   justify-content: space-between;
  //   word-break: break-word;
  // }
}