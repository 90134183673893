@import 'src/assets/styles/media';

.dropdownRow__item {
  margin-bottom: 1rem;
  margin-right: 0.25rem;
  // padding: 0.2rem 0 0 0.2rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  min-height: 1.5rem;
  word-wrap: break-word;
  word-break: break-word;
  page-break-inside: avoid;
  cursor: pointer;

  @include mobile-xl {
    font-size: 0.85rem;
    min-height: 1.35rem;
  }

  &.treatment {
    // width: 35rem;
    @include mobile-xl {
      width: auto;
    }
  }

  .dropdownRow__checkbox {
    width: 1.68rem;
    height: 1.5rem;
    border: 1px solid var(--color-darkGreen);
    border-radius: 1px;
    flex-shrink: 0;
    @include mobile-xl {
      height: 1.35rem;
      width: 1.35rem;
    }
  }

  .dropdownRow__text {
    padding-left: 0.7rem;
    padding-right: 1rem;
    min-height: 1.5rem;
    display: flex;
    @include mobile-xl {
      min-height: 1.35rem;
    }
    .paragraph{
      padding: 0.25rem 0;
      display: inline;
    }
  }

  @include mobile-xl {
    .dropdownRow__text {
      width: 100%;
      word-break: break-word;
    }
  }

  &.active {
    .dropdownRow__checkbox {
      background-color: var(--color-darkGreen);
    }
    .dropdownRow__text {
      background-color: #dadada;
    }
  }

  @include mobile-xl {
    padding-left: 1rem;
  }
}
