@import '../../../../../../assets/styles/media';

.anamnesisSummary {
  padding: 0 1.69rem;
  height: calc(100% - 1rem);
  overflow-y: hidden;
  display: flex;
  flex-direction: column;

  @include desktop-xl {
    padding-left: 0;
  }

  @include tablet-xxl {
    padding-right: 0.69rem;
  }

  @include mobile-xl {
    padding-right: 0;
  }
}

.anamnesisSummary__icon {
  margin-right: 1.19rem;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 5px;
  border: 2px solid var(--color-darkGreen);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mobile-xl{
    align-self: start;
    border: 1px solid var(--color-darkGreen);
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.25rem;
    margin-right: 0;
  }
}

.anamnesisSummary__img {
  width: 2.25rem;
  height: 2.25rem;
  object-position: center;
  object-fit: cover;
  image-rendering: pixelated;
  @include mobile-xl{
    width: 100%;
    height: 100%;
  }
}

.anamnesisSummary__info {
  font-size: 1rem;

  &.firstChild {
    padding-left: 0;
  }

  @include tablet-xl {
    padding-left: 0;
  }
  @include mobile-xl{
    font-size: 0.85rem;
    line-break: 1.2rem;
  }
}

.anamnesisSummary__header {
  display: flex;
  align-items: center;
  @include mobile-xl{
    flex-direction: column;
    align-items: flex-start;
  }
}

.anamnesis_table {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.anamnesis_table_body {
  // padding: 0 1rem;
  height: calc(100% - 3.5rem);
  overflow-y: auto;
  padding-right: 0.5rem;

  @include mobile-xl{
    height: 100%;
    padding-right: 0.5rem;
  }
}
