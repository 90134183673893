@import '../../assets/styles/media';

.finalPage {
  width: 100%;
  height: calc(100% - 4rem);
  overflow-y: auto;
  display: flex;
  justify-content: center;
  padding: 1rem 8rem 2rem 8rem;

  @include mobile-xl {
    padding: 0rem;
    height: 100%;
  }

  @include tablet-xl {
    padding: 0rem;
    height: 100%;
  }

  @include tablet-xxl {
    padding: 0rem;
    height: 100%;
  }

  .final_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include mobile-xl {
      width: 100%;
    }
    .final_bi_logos {
      display: flex;
      justify-content: space-evenly;
      width: 17rem;
      align-items: center;
      @include mobile-xl {
        width: 12rem;
      }
      .final__welcome_img{
        height: auto;
        width: 100% !important;
        @include tablet-xl   {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
    .final__logo {
      display: flex;
      justify-content: space-between;
      height: 10rem;

      .final__img {
        width: 9rem;
      }

      .final__welcome_img {
        width: 10rem;
      }

      @include tablet-xl {
        height: 3.5rem;
        margin-bottom: 1rem;
      }

      @include mobile-xl {
        height: 3.5rem;
        align-items: center;
        margin-bottom: 0.25rem;

        .final__img {
          width: 7rem;
          height: 2.5rem;
        }

        .final__welcome_img {
          width: 7rem;
          height: 2.5rem;
        }
      }
    }

    .finalPage__inner {
      display: flex;
      flex-direction: column;
      text-align: center;
      border-radius: 1rem;
      height: calc(100% - 8rem);
      filter: drop-shadow(0px 4px 25px rgba(170, 154, 130, 0.8));

      @include mobile-xl {
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        filter: none;
        box-shadow: 0px -4px 4px rgba(170, 154, 130, 0.25);
        border-radius: 10px 10px 0px 0px;
      }

      .finalPage_header {
        height: 3rem;
        width: 100%;
        content: '';
        display: flex;
        flex-direction: row;
        background: #f9ac5a;
        box-sizing: border-box;
        border-radius: 1rem 1rem 0 0;
      }

      .finalPage_body {
        background-color: white;
        width: 100%;
        height: calc(100% - 3rem);
        display: flex;
        padding: 2rem;
        background: url('../../assets/nImages/bg/720x330_stars.png');
        background-repeat: round;
        background-size: cover;

        @include mobile-xl {
          border: none;
          padding: 0.5rem;
          border-radius: 0 0 1rem 1rem;
          background: url('../../assets/nImages/bg/240x370_stars.png');
          background-repeat: round;
          background-size: cover;
        }
        @include tablet-xxl {
          border: none;
          padding: 0.5rem;
          border-radius: 0 0 1rem 1rem;
          background: url('../../assets/nImages/bg/1024X1366.png');
          background-repeat: round;
          background-size: cover;
        }
      }

      .finalPage_Content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 3.5px solid var(--color-darkGreen);

        @include mobile-xl {
          border: none;
        }

        .finalPage_StarBg {
          // background: url('../../assets/nImages/stars.png');
          // background-repeat: repeat-y;
          // background-size: contain;
          height: 70%;
          width: 80%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          @include mobile-xl {
            height: 75%;
            width: 96%;
          }
        }

        .finalPage__image {
          display: flex;
          justify-content: center;

          width: 100%;

          @include mobile-xl {
            background: none;
          }

          .finalPage__img {
            width: 8rem;
            height: 10rem;
            border: 4px solid var(--color-darkGreen);
            box-sizing: border-box;
            border-radius: 10px;
            object-fit: fill;
          }
        }

        .finalPage__info {
          display: flex;
          flex-direction: column;
          align-items: center;

          .finalPage__title {
            font-size: 1.94rem;
            padding: 1.75rem 0 1rem 0;
            font-weight: 700;
            word-spacing: 3px;
            color: var(--color-darkGreen);
          }

          .finalPage__subtitle {
            color: var(--color-fontBlack);
            font-size: 1.12rem;
            font-weight: 600;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
}

.actions {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
}
