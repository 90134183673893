@import "src/assets/styles/media";

.anamnesisDropdown__list {
  width: 100%;
  margin: 0;
  padding: 1.75rem 0.94rem 0 1.75rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @include mobile-xl {
    padding: 1rem 0.5rem 0;
  }
}

.anamnesisDropdown__item {
  display: flex;
  width: 100%;
  border-radius: 0.37rem;
  overflow: hidden;
  cursor: pointer;
}

.anamnesisDropdown__question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  color: var(--color-fontBlack);
  padding: 0.31rem 0.8rem 0.31rem 1.81rem;

  @include mobile-xl {
    padding-left: 1rem;
    font-size: 0.85rem;
    line-height: 1.2rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.anamnesisDropdown_question_selected{
  color: white;
  background-color: var(--color-darkGreen);
  margin: 0.3rem 0;
}

.anamnesisDropdown__answer {
  background-color: var(--color-darkGreen);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 0.5rem;
  border-radius: 5px;
}

.anamnesisDropdown__content {
  padding-left: 1.25rem;
  display: flex;
  align-items: center;
  width: 50%;
  color: var(--color-white);

  @include mobile-xl {
    padding-left: 0.25rem;
  }

}

.anamnesisDropdown__avatar {
  border-radius: 5px;
  overflow: hidden;
  margin-right: 0.7rem;
  width: 2.75rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile-xl {
    height: 2.5rem;
    width: 2.5rem;
  }
}

.anamnesisDropdown__svg {
  width: 100%;
  height: 100%;
  object-position: center;
}

.anamnesisDropdown__img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.anamnesisDropdown__text {
  width: calc(100% - 3.45rem);
  padding: 0.31rem 0.8rem 0.31rem 0.31rem;
  font-size: 1.125rem;
  line-height: 1.56rem;
  font-weight: 700;

  @include mobile-xl {
    width: calc(100% - 1.75rem);
    font-size: 0.85rem;
    line-height: 1.2rem;
    padding: 0;
    word-break: break-all;
  }
}