@import '../../assets/styles/media';

.patientCases__content {
  width: 100%;
  height: 100%;
  display: flex;
  color: #4f4f4f;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  .patientCases__information {
    padding: 1rem 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    @include mobile-xl{
      padding: 0;
      height: calc(100% - 0.5rem);
    }
    .patientCases__details {
      display: flex;
      margin-bottom: 2rem;
      @include tablet-xxl{
        margin-bottom: 1rem;
      }
      @include mobile-xl{
        margin-bottom: 0.5rem;
      }
      .patientCases__picture {
        margin-right: 0.75rem;
        @include mobile-xl{
          margin-right: 0.25rem;
        }
        .patientCases__img {
          object-position: center;
          object-fit: cover;
          width: 6rem;
          height: 7.5rem;
          border: 2px solid #4fb1c2;
          border-radius: 10px;
          @include mobile-xl{
            width: 4.75rem;
            height: 5.75rem;
          }
        }
      }
      .patientCases__info {
        width: calc(100% - 1rem);
        display: flex;
        align-items: center;
        .patientCases__person {
          margin-left: 1rem;
          .patientCases__name {
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 2rem;
            @include mobile-xl{
              font-size: 1.1rem;
              line-height: 1.25rem;
              margin-bottom: 0.5rem;
            }
          }
          .patientCases__gender {
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.5rem;
            @include mobile-xl{
              font-size: 0.85rem;
              line-height: 1rem;
            }
          }
          .patientCases__description {
            width: fit-content;
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.25rem;
            margin-top: 1rem;
            @include mobile-xl{
              font-size: 0.75rem;
              line-height: 1rem;
              font-weight: 600;
              margin-top: 1rem;
            }
            &.pc_highlight{
              background-color: #F9AC5A;
              color: white;
              border-radius: 5px;
              padding: 0.5rem;
            }
          }
        }
      }
      .pc_list{
        .patientCases__name {
          font-size: 1.25rem !important;
          line-height: 1.75rem !important;
        }
        .patientCases__gender {
          font-size: 1.12rem !important;
          line-height: 1.5rem !important;
        }
      }
    }
    .patientCases__AddDetails {
      font-size: 1rem;
      line-height: 1.25rem;
      display: flex;
      flex-direction: row;
      height: auto;
      min-height: 10rem;
      justify-content: space-between;
      width: 100%;
      overflow-y: auto;
      padding-right: 0.5rem;
      :first-child{
        margin-right: 2rem;
      }
      &.pc_list{
        justify-content: stretch;
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
        :first-child{
          margin-right: 0rem;
        }
      }
      @include mobile-xl {
        justify-content: stretch;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.5rem;
        font-size: 0.85rem;
        line-height: 1rem;
        :first-child{
          margin-right: 0;
        }
      }
      .patientCases__history {
        width: 50%;
        background: linear-gradient(180deg, #c2dec4 0%, #a3d7e9 100%);
        margin-bottom: 0.5rem;
        display: flex;
        height: auto;
        flex-direction: column;
        padding: 1rem 1.5rem;
        &.pc_list{
          width: 100%;
        }
        @include mobile-xl {
          width: 100%;
        }
        .patientCases__historyTitle {
          font-weight: 800;
          font-size: 1.25rem;
          line-height: 1.75rem;
          @include mobile-xl {
            font-size: 1rem;
            line-height: 1.5rem;
          }
        }
        .patientCases__historyText {
          font-weight: 500;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 0.4rem;
            background-color: var(--color-white);
            border-radius: 0.34rem;
            overflow: hidden;
            
            @include tablet-xl {
              width: 0.45rem;
              height: 0.45rem;
            }

            @include mobile-xl {
              width: 0.25rem;
              height: 4px;
            }
          }

          &::-webkit-scrollbar-thumb {
            background-color: var(--color-gray8);
            border-radius: 0.34rem;
          }
        }
        p {
          margin-top: 5px;
        }
      }
    }
  }
}

.patientCases__start{
  width: 100%;
  display: flex;
  justify-content: center;
}