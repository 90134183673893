@import 'src/assets/styles/media';

.survey {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .survey_container {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
  }

  .comment_container {
    width: 40%;
    height: 100%;
    padding: 0rem 1.62rem;
  }
  @include tablet-xl {
    padding: 0;
    padding-right: 5px;
    flex-direction: column;

    .survey_container {
      width: 100%;
      height: auto;
      display: block;
    }

    .comment_container {
      width: 100%;
      padding: 0;
      height: auto;
      display: block;
      min-height: 15rem;
    }
  }

  @include mobile-xl {
    padding: 0;
    flex-direction: column;
    padding-right: 3px;

    .survey_container {
      width: 100%;
      height: auto;
    }

    .comment_container {
      width: 100%;
      padding: 0;
      height: auto;
      min-height: 9rem;
    }
  }
}

.survey__item {
  width: 100%;
  margin-bottom: 1.8rem;
  // height: 50%;
  @include mobile-xl {
    height: auto;
    margin-bottom: 0.25rem;
  }
  @include tablet-xl {
    height: auto;
    margin-bottom: 1rem;
  }
}

.survey__header {
  background: linear-gradient(180deg, #c2dec4 0%, #a3d7e9 100%);
  width: 100%;
  padding: 1rem;

  @include mobile-xl {
    padding: 0.5rem;
  }
  .survey_card {
    color: var(--color-fontBlack);
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 700;
    @include mobile-xl {
      font-size: 0.85rem;
      line-height: 1rem;
    }
  }
}

.survey__list {
  //width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.survey__assessment {
  margin-top: 1.8rem;
  padding: 0 1.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  @include mobile-xl {
    margin-top: 1.25rem;
  }
  // @include desktop-xl {
  // }
}

.survey__satisfied {
  margin-bottom: 1.8rem;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  max-width: 35rem;

  @include tablet-xl {
    width: 100%;
  }

  @include mobile-xl {
    margin-bottom: 0.5rem;
    width: 100%;
    gap: 0;
  }
}

.survey__value {
  width: 100%;
  font-size: 1.125rem;
  font-weight: 600;
  @include mobile-xl {
    font-size: 0.875rem;
    line-height: 1rem;
    width: auto;
  }
  &:first-child{
    text-align: left;
  }
  text-align: right;
}

.survey__comment {
  border: 1px solid #4fb1c2;
  border-radius: 10px;
  height: 100%;

  .comment__header {
    background: linear-gradient(180deg, #c2dec4 0%, #a3d7e9 100%);
    color: var(--color-fontBlack);
    padding: 1.25rem;
    border-radius: 10px 10px 0 0;

    .comment_card {
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: 700;
      @include mobile-xl {
        font-size: 0.85rem;
        line-height: 1rem;
      }
    }
  }
  .comment__body {
    width: 100%;
    height: calc(100% - 4rem);
  }

  .comment__input {
    width: 100%;
    border: none;
    padding: 0.5rem 1.12rem;
    background-color: transparent;
    font-size: 1rem;
    outline: none;
    height: 100%;
    box-sizing: border-box;
    resize: none;
  }
  @include mobile-xl {
    margin-top: 0.5rem;
    height: calc(100% - 0.5rem);
  }
}
