@import 'src/assets/styles/media';

.menu {
  overflow-y: hidden;
  padding-left: 1rem;
  height: 100%;
  width: 100%;

  @include tablet-xxl {
    padding: 0;
    height: auto;
    overflow-x: auto;
    width: 100%;
    height: 100%;
  }
}

.headerLogo {
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerLogo_png {
  width: 9rem;
  height: 3.5rem;
}

.menuCollapse {
  position: absolute;
  top: 0;
  right: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-lightgreen);
  z-index: 1;
}

.menuCollapse__button {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.menuCollapse__icon {
  width: 1.15rem;
  height: 1.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(0deg);
  transition: 0.3s;

  &.rotate {
    transform: rotate(180deg);
  }
}

.menuCollapse__svg {
  fill: var(--color-white);
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.nav_container {
  height: calc(100% - 6rem);
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  @include tablet-xxl {
    padding: 0;
    height: 100%;
    overflow-x: hidden;
  }
}

.nav {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: var(--color-white);
  box-shadow: 0px 4px 25px rgba(170, 154, 130, 0.5);
  border-radius: 10px 0px 0px 10px;
  flex-shrink: 0;
  padding: 2rem 0 0 1.429rem;
  height: calc(100% - 5rem);
  position: relative;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  @include tablet-xxl {
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    border-radius: 10px 10px 0px 0px;
  }
}

.nav_list_holder {
  // padding-right: 0.75rem;
  height: calc(100% - 5.5rem);
  // display: flex;
  @include desktop-xl {
    height: calc(100% - 7.5rem);
  }

  @include tablet-xxl {
    height: 100%;
    width: 100%;
    padding: 0.5rem 0.5rem 0rem 0.5rem;
  }
}
.nav__list {
  height: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  padding-right: 0.5rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  scroll-behavior: smooth;
  scrollbar-color: var(--color-scrollBar) var(--color-scrollGrayBackground);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.4rem;
    background-color: var(--color-gray5);
    border-radius: 0.34rem;
    overflow: hidden;
    
    @include tablet-xl {
      width: 0.45rem;
      height: 0.45rem;
    }

    @include mobile-xl {
      width: 0.25rem;
      height: 2.5px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-darkGreen);
    border-radius: 0.34rem;
  }

  @include tablet-xxl {
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    padding-right: 0;
    padding-bottom: 1px;
    align-items: flex-end;
    transform:rotateX(180deg);
    -moz-transform:rotateX(180deg); /* Mozilla */
    -webkit-transform:rotateX(180deg); /* Safari and Chrome */
    -ms-transform:rotateX(180deg); /* IE 9+ */
    -o-transform:rotateX(180deg); /* Opera */
    &::-webkit-scrollbar {
      height: 0.5rem;
      background-color: var(--color-scrollBackground);
      border-radius: 0.34rem;
      overflow: hidden;
      
      @include tablet-xl {
        width: 0.45rem;
        height: 0.45rem;
      }

      @include mobile-xl {
        width: 0.25rem;
        height: 4px;
      }
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-scrollBar);
      border-radius: 0.34rem;
    }
  }
}

.nav__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.45rem;
  padding-right: 0.85rem;
  font-size: 0.8572rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: left;

  @include tablet-xxl {
    justify-content: center;
    align-items: flex-start;
    padding: 0.5rem;
    // padding-top: 1rem;
    position: relative;
    height: 100%;
    min-width: 7.5rem;
    transform:rotateX(180deg);
    -moz-transform:rotateX(180deg); /* Mozilla */
    -webkit-transform:rotateX(180deg); /* Safari and Chrome */
    -ms-transform:rotateX(180deg); /* IE 9+ */
    -o-transform:rotateX(180deg); /* Opera */
  }

  @include mobile-xl {
    margin-right: 0.2rem ;
    padding: 0.2rem;
    padding-top: 0.5rem;
    line-height: 1rem;
  }

}

.nav__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  @include tablet-xxl{
    text-align: center;
  }
}

.nav__svg {
  filter: invert(29%) sepia(0%) saturate(0%) hue-rotate(157deg) brightness(100%) contrast(91%);
  width: 1.7rem;
  height: 1.7rem;
  object-position: center;
  overflow: hidden;

  @include mobile-xl {
    width: 1.5rem;
    height: 1.5rem;
  }

  img {
    width: 1.2rem;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.nav__prop {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  transition: 0.3s;

  &.collapsed {
    margin-left: 1.5rem;
  }

  @include tablet-xxl {
    flex-direction: column;
    justify-content: center;
    margin: 0;
    height: 100%;
  }
}

.nav__text {
  margin-left: 0.89rem;

  @include tablet-xxl {
    text-align: center;
    margin: auto;
  }

  @include mobile-xl {
    margin-top: 0.7rem;
  }
}

.nav__item.active {
  color: var(--color-highlightGreen);
  font-family: Poppins;
  font-size: 0.8572rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: left;
  background: var(--color-linearGreen);
  @include mobile-xl {
    line-height: 1rem;
  }
}

.menu__pic {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 18rem;
  position: absolute;
  bottom: 0;

  @include tablet-xxl {
    display: none;
  }
}

.menu__img {
  max-width: 100%;
  max-height: 11rem;
  object-position: bottom;
  object-fit: contain;
}
.menu_green_bubble {
  position: absolute;
  bottom: 0;
  right: 1rem;
  .green_bubble_image {
    height: 5.5rem;
  }
}
.finished{
  cursor: pointer;
  color: var(--color-nav-passed);
}
.clickable {
  cursor: pointer !important;
}

.activeIcon {
  filter: invert(61%) sepia(54%) saturate(425%) hue-rotate(141deg) brightness(94%) contrast(83%);
}

.navPassed {
  filter: invert(89%) sepia(14%) saturate(177%) hue-rotate(191deg) brightness(86%) contrast(87%);
}
