@import 'src/assets/styles/media';

.heading {
  padding: 0.75rem 1rem 0.75rem 2.25rem;
  width: calc(100% - 23rem);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  overflow: hidden;
  color: white;
  @include tablet-xxl {
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 0;
  }
}

.heading__present {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @include tablet-xxl {
    justify-content: center;
    padding-left: 1rem;
  }
  @include mobile-xl{
    padding: 0.5rem;
  }
}

.heading__title {
  display: flex;
  font-size: 2rem;
  font-weight: 700;
  line-height: 3rem;

  @include tablet-xl {
    font-size: 1.8rem;
  }
  @include mobile-xl {
    font-size: 1.12rem;
    line-height: 1.5rem;
    margin-bottom: 0.25rem;
  }
}

.heading__subtitle {
  font-size: 1.12rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;

  &:first-letter {
    text-transform: uppercase;
  }
  @include tablet-xl {
    font-size: 1.1rem;
  }
  @include mobile-xl {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}

.heading__picture {
  height: 8rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  right: 122px;
  top: 45px;

  &.ranking {
    top: 65px;
    @include mobile-xl{
      top: 0px;
    }
    @include tablet-xxl{
      top: 15px;
    }
  }
  @include mobile-xl{
    height: 8rem;
  }
  @include tablet-xl{
    height: 6rem;
  }
  @include tablet-xxl {
  //  display: none;
    position: relative;
    right: 0px;
    top: 0px;
    // height: 100%;
    height: 6rem;
  }

}

.heading__img {
  max-width: 100%;
  max-height: 100%;
  object-position: center;

  @include tablet-xxl {
    // max-height: 8.5rem;
  }

  @include tablet-xl {
    // max-height: 7.5rem;
  }
}
.heading__ranking_helpers {
  font-size: 0.92rem;
  line-height: 1.3rem;
  text-transform: capitalize;
  margin-top: 5px;
  color: var(--color-fontBlack);
  @include mobile-xl{
    font-size: 0.65rem;
    line-height: 0.75rem;
  }
}
