$desktop-xxxl: 1921px;
$desktop-xxl: 1800px;
$desktop-xl: 1700px;
$desktop-l: 1600px;
$desktop-m: 1500px;

$tablet-xxl: 1300px;
$tablet-xl: 900px;

$mobile-xxl: 750px;
$mobile-xl: 600px;

@mixin tablet-min-xl {
  @media (min-width: $tablet-xl) {
    @content;
  }
}

@mixin desktop-min-l {
  @media (min-width: $desktop-m) {
    @content;
  }
}

@mixin desktop-xxl {
  @media (max-width: $desktop-xxl) {
    @content;
  }
}

@mixin desktop-xxxl {
  @media (min-width: $desktop-xxxl) {
    @content;
  }
}

@mixin desktop-min-xxl {
  @media (min-width: $desktop-xxl) {
    @content;
  }
}

@mixin desktop-min-xxxl {
  @media (min-width: $desktop-xxxl) {
    @content;
  }
}

@mixin desktop-xl {
  @media (max-width: $desktop-xl) {
    @content;
  }
}

@mixin desktop-l {
  @media (max-width: $desktop-l) {
    @content;
  }
}

@mixin desktop-m {
  @media (max-width: $desktop-m) {
    @content;
  }
}

@mixin tablet-xxl {
  @media (max-width: $tablet-xxl) {
    @content;
  }
}

@mixin tablet-xl {
  @media (max-width: $tablet-xl) {
    @content;
  }
}

@mixin mobile-xl {
  @media (max-width: $mobile-xl) {
    @content;
  }
}

@mixin mobile-xxl {
  @media (max-height: $mobile-xxl) {
    @content;
  }
}


