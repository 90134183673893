@import '../../assets/styles/media';

.introContainer {
  height: 100%;
  display: flex;
  overflow: hidden;

  .introLeftContainer {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    padding-bottom: 1.5rem;
    @include mobile-xl {
      padding: 0;
    }
  }

  @include tablet-xl {
    overflow-y: unset;
  }
}


.selectedPatient {
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;

  @include tablet-xl {
    overflow-y: unset;
  }
}

.patientField {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TermsField {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1.45rem;
  padding-top: 1rem;

  & > div:first-child {
    cursor: pointer;
  }

  @include tablet-xl {
    max-width: 95%;
    padding-top: 0rem;
    font-size: 1rem;
  }
  @include mobile-xl {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

.termsText {
  margin-left: 1.5rem;
}

.link {
  color: var(--color-link);
  cursor: pointer;
}
