@import '../../../../assets/styles/media';

.physical {
  height: 100%;
  width: 100%;
  display: flex;
  overflow-y: hidden;

  @include mobile-xl {
    flex-direction: column;
    padding-left: 0.5rem;
  }
}

.physical__inner {
  width: 100%;
  height: 100%;
  display: flex;

  @include mobile-xl {
    flex-direction: column;
    margin-top: 0.25rem;
    padding-right: 0.5rem;
  }
}

.physical__menu {
  display: flex;
  height: 100%;
  align-items: center;
  justify-items: flex-start;
  z-index: 100;

  @include mobile-xl {
    z-index: 0;
    align-items: flex-start;
    height: 2.25rem;
  }

  @media screen and (max-width: 1025px) and (min-height: 1000px) {
    height: 60%;
  }
}

.physical__scroll {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.625rem;

  &:last-child {
    margin-right: 0;
    margin-left: 0.625rem;
  }
}

.physical__scrollButton {
  background-color: transparent;
  border: none;
  padding: 0.25rem;
  width: 1.19rem;
  height: 1.194rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include mobile-xl {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.physical__scrollSvg {
  width: 100%;
  height: 100%;
  &.physical__scrollLeft{
    transform: rotate(180deg);
  }
}

.physical__menuList {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include mobile-xl {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
  }
}

.physical__menuItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.625rem;
}

.physical__menuButton {
  width: 10rem;
  display: flex;
  align-items: center;
  padding: 0.31rem 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: var(--color-fontBlack);
  background-color: #cbe4e9;
  border: none;
  cursor: pointer;
  transition: 0.1s;

  &.active {
    background-color: #4f4f4f;
    color: var(--color-white);
  }

  @include tablet-xl {
    padding: 0.31rem 1.2rem;
  }

  @include mobile-xl {
    font-size: 0.85rem;
    padding: 0.25rem 0.5rem;
    width: auto;
    margin-bottom: 0.25rem;
  }
}

.image_container {
  display: flex;
  flex-grow: 1;

  @include mobile-xl {
    flex-direction: column;
    overflow-y: auto;
  }
}

.physical__details {
  width: calc(100% - 10rem);
  height: 100%;

  @include mobile-xl {
    width: 100%;
    height: calc(100% - 3rem);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0.25rem 0rem;
  }

  .physical__holder {
    width: 100%;
    height: calc(100% - 3.5rem);
    display: flex;
    align-items: center;

    @include tablet-xxl {
      flex-direction: column;
      height: calc(100% - 3.5rem);
    }
  }

  .physical_settings {
    height: 3.5rem;
    z-index: 100;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 75%;
    // left: 30%;
    // bottom: 0;
    // position: absolute;

    @include tablet-xxl {
      width: 100%;
    }

    justify-content: center;
    align-items: center;

    .ps_label {
      font-weight: 800;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .zoom_disabled {
      filter: invert(100%) sepia(5%) saturate(10%) hue-rotate(221deg) brightness(150%) contrast(73%);

      &.ps_zminus:hover,
      &.ps_zplus:hover {
        transform: none;
        cursor: auto;
      }
    }

    .ps_options {
      display: flex;
      flex-direction: row-reverse;

      .ps_zminus,
      .ps_zplus {
        cursor: pointer;
        height: 1.75rem;
      }

      img:hover {
        transform: scale(1.1, 1.1);
        transform: scale3d(1.1, 1.1);
      }

      .ps_zplus {
        margin-left: 0.75rem;
      }
    }
  }
}

.physical__position {
  margin-bottom: 5px;
  // width: calc(100% - 3rem);
  width: 100%;
  padding: 2px;

  @include tablet-xxl {
    display: flex;
  }

  @include mobile-xl {
    width: 100%;
    gap: 1rem;
    padding: 0.5rem 1rem;
  }
}

.physical__label {
  height: 1.8rem;
  // border-radius: 0.46rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  cursor: pointer;
  background-color: #DADADA;
  color: #969393;
  @include mobile-xl{
    height: auto;
  }
}

.physical__label.active {
  background: linear-gradient(180deg, #C2DEC4 0%, #A3D7E9 100%);
  color: var(--color-fontBlack);
}

.physical__radiobutton {
  display: none;
}

.physical__sideName {
  font-size: 1rem;
  font-weight: 700;

  text-transform: capitalize;
  color: var(--color-fontBlack);

  @include mobile-xl {
    font-size: 1rem;
  }
}

.physical__info {
  width: calc(50% - 1.75rem);
  height: 100%;
  display: flex;
  align-items: center;

  @include desktop-xxl {
    width: calc(50% - 2rem);
  }

  @include desktop-xl {
    width: calc(50% - 1rem);
  }


  @include tablet-xxl {
    width: calc(100% - 0.5rem);
    margin-bottom: 1.5rem;
    height: calc(100% - 1.5rem);
    // flex-direction: row-reverse;
    &.physical_back{
      flex-direction: row-reverse;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mobile-xl {
    width: 100%;
    height: 100%;
    border-right: none;
    margin: 0;
    margin-bottom: 1rem;
    padding: 0 0 1rem 0;
    border-bottom: 2px solid var(--color-gray5);
  }
  &.physical_back{
    @include mobile-xl {
      flex-direction: row-reverse;
    }
  }
}

.physical__skeleton {
  padding: 0.5rem;
  flex: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 1030px) and (min-height: 1000px) {
    justify-content: flex-start;
    margin-top: 2rem;
  }

  // @include desktop-xxl {
  //   margin-right: 0.5rem;
  // }

  // @include tablet-xl {
  //   justify-content: flex-start;
  //   margin-top: 2rem;
  // }

  // @include mobile-xl {
  //   overflow-y: unset;
  //   width: 12.05rem;
  // }


}

.physical__side {
  font-size: 1.375rem;
  font-weight: 700;
  // color: var(--color-gray2);
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #C2DEC4 0%, #A3D7E9 100%);
  margin-bottom: 2.5rem;
  padding: 0.25rem;
  width: 100%;
  // @include desktop-min-xxl {
  //   align-items: flex-start;
  //   height: 5rem;
  // }
}

.physical__partInfo {
  flex-grow: 1;
  overflow-y: auto;
  flex: 40%;
  height: calc(100% - 5rem);
  padding-right: 1rem;
  width: 100%;
  display: flex;
  margin-top: 3rem;

  @include desktop-m {
    margin-top: 1rem;
  }

  @include mobile-xl {
    padding-right: 0.1rem;
  }
}

.physical__partList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.physical__partItem {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--color-darkGreen);
  color: var(--color-white);
  border-radius: 0.375rem;
  margin-bottom: 0.56rem;
  width: 100%;
  max-width: 12rem;

  &.imageItem {
    background-color: var(--color-lightOrange);
  }

  @include desktop-xl {
    padding-right: 1rem;
  }

  @include mobile-xl {
    padding-right: 0rem;
    padding: 0.25rem 0.25rem 0 0.5rem;
  }
}

.physical__partItem_last {
  background-color: var(--color-darkGreen);

  & * {
    color: white !important;
  }
}

.physical__partName {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.75rem;

  @include desktop-l {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  @include mobile-xl {
    font-size:0.85rem;
    line-height: 1rem;
    margin-bottom: 0.25rem;
  }
}

.physical__partText {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4rem;

  @include desktop-l {
    font-size: 1rem;
    line-height: 1.1rem;
  }

  @include mobile-xl {
    font-size: 0.85rem;
    line-height: 1rem;
  }
}

.physical__exams {
  height: 100%;

  @include tablet-xxl {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  @include mobile-xl {
    width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  align-items: center;
  justify-content: flex-end;
  display: flex;
}

.additional_container {
  height: calc(100% - 6rem);
  background: linear-gradient(180deg, #c2dec4 0%, #a3d7e9 100%);
  border-radius: 0px 0px 0px 10px;
  width: 15rem;
  padding: 0 5px;

  @include desktop-l {
    width: 15rem;
  }

  @include tablet-xxl {
    width: 100%;
  }

  @include mobile-xl {
    width: 100%;
    height: 100%;
    padding-right: 0.5rem;
    display: flex;
    flex-direction: column;
  }
}

.physical__examsTitle {
  font-size: 1rem;
  font-weight: 700;
  color: var(--color-gray2);
  text-align: center;
  margin-top: 0.85rem;
  @include mobile-xl{
    font-size: 0.85rem;
  }
}

.physical__examsList {
  height: inherit;
  overflow-y: auto;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.34rem;
    background-color: var(--color-scrollBackground);
    border-radius: 0.34rem;
    overflow: hidden;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-scrollBar);
    border-radius: 0.34rem;
  }

  @include desktop-xxl {
    padding-right: 2rem;
  }

  @include desktop-xl {
    padding-right: 1.2rem;
  }

  @include tablet-xxl {
    max-height: 40.75rem;
  }

  @include mobile-xl {
    padding-right: 0.5rem;
    height: 100%;
  }
}

.physical__examsItem {
  width: 100%;
  margin-bottom: 0.3rem;
}

.physical__examsName {
  margin: 0 0 0.8rem;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  color: var(--color-gray2);
  background-color: var(--color-white);
  text-align: center;
  cursor: pointer;

  &.active {
    background-color: var(--color-darkGreen);
    color: var(--color-white);
  }

  .physical_answer {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    padding: 0;
    @include mobile-xl{
      font-size: 0.78rem;
      line-height: 1rem;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  @include desktop-l {
    font-size: 1rem;
  }

  @include tablet-xxl {
    margin-bottom: 0.5rem;
  }

  @include mobile-xl {
    font-size: 0.85rem;
  }
}

.physical__examsText {
  margin: 0 0.312rem 0.312rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.69rem;
  // color: var(--color-gray2);

  @include desktop-l {
    font-size: 1rem;
    line-height: 1.1rem;
  }

  @include tablet-xxl {
    margin: 0 0.5rem 0.5rem;
  }

  @include mobile-xl {
    font-size: 1.2rem;
  }
}

.physical__image {
  font-size: 1.125rem;
  line-height: 1.69rem;
  // color: var(--color-gray2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.physical__image_view {
  text-align: center;
  padding: 0.312rem 0.5rem;
  border-radius: 0.375rem;
  width: max-content;
  margin: 0 0.312rem 0.312rem;
  background-color: var(--color-gray5);
  cursor: pointer;
}

.physical__partImage {
  margin-top: 0.5rem;
  width: 100%;
  height: 10rem;
  overflow: hidden;
  object-fit: contain;
  object-position: center;

  @include mobile-xl {
    height: 12rem;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    cursor: pointer;
  }
}

.additional__image {
  margin-bottom: 0.8rem;
}