@import "src/assets/styles/media";

.carouselPopup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.carouselPopup__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 90vw;
  width: fit-content;
  max-width: 90vw;
  height: 90vh;

  @include mobile-xl {
    height: 80vh;
  }
}

.carouselPopup__pic {
  width: 100%;
  height: 100%;
  padding: 0.81rem;
  position: relative;
  background-color: var(--color-white);
  border: 8px solid var(--color-darkGreen);
  border-radius: 0.75rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include desktop-l {
    margin: 1.875rem;
    border: 6px solid var(--color-darkGreen);
  }
  @include mobile-xl {
    margin: 0.25rem;
  }
}

.carouselPopup__img {
  width: fit-content;
  object-position: center;
  object-fit: contain;
}

.carouselPopupClose {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

}

.carouselPopup__close {
  position: absolute;
  top: -1.845rem;
  right: -1.845rem;
  padding: 0.81rem;
  width: 3.69rem;
  height: 3.69rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color-darkGreen);
  cursor: pointer;
  z-index: 15;

  @include desktop-l {
    top: -1.47rem;
    right: -1.47rem;
    width: 2.69rem;
    height: 2.69rem;
  }

  @include mobile-xl {
    top: -1.2rem;
    right: -1.3rem;
  }
}

.carouselPopup__closeSvg {
  fill: var(--color-white);
  width: 100%;
  height: 100%;
  object-position: center;
}


.carouselPopup__sliderButton {
  background: none;
  border: none;
  width: 50px;
  padding: 0px;
  cursor: pointer;
}

.carouselPopup__sliderButton__nextPos {
  position: absolute;
  top: 43%;
  z-index: 2;
  right: 0;
}

.carouselPopup__sliderButton__prevPos {
  position: absolute;
  top: 43%;
  z-index: 2;
  left: 0;
}

.carouselPopup__sliderButton__img {
  width: 100%;
  height: 100%;
}

.carouselPopup__pictureContainer {
  margin: auto;
}

@media screen and (min-width: 100px) and (max-width: 599px) {
  .carouselPopup__sliderButton__nextPos {
    bottom: 2%;
    top: auto;
    width: 35px;
  }

  .carouselPopup__sliderButton__prevPos {
    bottom: 2%;
    top: auto;
    width: 35px;
  }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
  .carouselPopup__sliderButton__nextPos {
    right: 2%;
  }

  .carouselPopup__sliderButton__prevPos {
    left: 2%;
  }
}

@media screen and (min-width: 701px) and (max-width: 1300px) {
  .carouselPopup__sliderButton__nextPos {
    right: 2%;
  }

  .carouselPopup__sliderButton__prevPos {
    left: 2%;
  }
}

.physical_settings {
  // height: 3.5rem;
  z-index: 100;
  // position: absolute;
  display: flex;
  flex-direction: column;
  // bottom: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
  padding-bottom: 0.75rem;

  .ps_label {
    font-weight: 800;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .zoom_disabled {
    filter: invert(100%) sepia(5%) saturate(10%) hue-rotate(221deg) brightness(150%) contrast(73%);

    &.ps_zminus:hover,
    &.ps_zplus:hover {
      transform: none;
      cursor: auto;
    }
  }

  .ps_options {
    display: flex;
    flex-direction: row-reverse;

    .ps_zminus,
    .ps_zplus {
      cursor: pointer;
      height: 1.75rem;
    }

    img:hover {
      transform: scale(1.1, 1.1);
      transform: scale3d(1.1, 1.1);
    }

    .ps_zplus {
      margin-left: 0.75rem;
    }
  }
}
