@import '../../assets/styles/media';

*{
  scrollbar-color: var(--color-scrollBar) var(--color-scrollGrayBackground);
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
}
::-webkit-scrollbar {
  width: 0.49rem;
  height: 0.49rem;
  background-color: var(--color-scrollGrayBackground);
  border-radius: 0.34rem;
  overflow: hidden;
  background-clip: padding-box;
  -webkit-overflow-scrolling: touch;
  -webkit-appearance: none;

  @include tablet-xl {
    width: 0.45rem;
    height: 0.45rem;
  }

  @include mobile-xl {
    width: 0.25rem;
    height: 4px;
  }
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-scrollBar);
  border-radius: 0.34rem;
}

html {
  box-sizing: border-box;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  color: var(--color-app-font);
  scrollbar-color: var(--color-scrollBar) var(--color-scrollGrayBackground);
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;

  @include desktop-xxxl {
    font-size: 22px;
  }

  @include desktop-min-xxl {
    font-size: 14px;
  }

  @include desktop-xxl {
    font-size: 15px;
  }

  @include desktop-xl {
    font-size: 14px;
  }

  @include desktop-l {
    font-size: 13px;
  }

  // @include desktop-xxl {
  //   font-size: 17px;
  // }

  // @include desktop-xl {
  //   font-size: 16px;
  // }

  // @include desktop-l {
  //   font-size: 15px;
  // }

  // @include desktop-m {
  //   font-size: 12px;
  // }

  // @include tablet-xxl {
  //   font-size: 14px;
  // }

  @include tablet-xl {
    font-size: 14px;
  }

  @include mobile-xl {
    font-size: 13px;
  }
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  margin: 0;
}

.hidden {
  display: none;
}

.no-scroll {
  overflow: hidden;
}

.paragraph {
  margin: 0.3rem 0;
  word-break: break-word;
  @include mobile-xl {
    margin: 0.19rem 0;
  }
}

.header_paragraph {
  margin: 0;
  line-height: 1.8rem;

  @include mobile-xl {
    line-height: 1rem;
  }
}

// React Responsice Carousel Custom Sytles

.carousel-root {
  width: inherit;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000;
}

.carousel.carousel-slider {
  overflow: visible;
}

.carousel .control-dots {
  position: absolute;
  bottom: -30px;
  @include mobile-xl {
    bottom: 10px;
  }
}

.carousel .control-dots .dot {
  background: #ffffff;
  border: 2px solid var(--color-darkGreen);
  width: 10px;
  height: 10px;
  box-shadow: none;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: var(--color-darkGreen);
}

.carousel .slide img {
  width: fit-content;
}

// @media screen and (min-width: 1301px) {
//   .carousel .slide img {
//     max-width: 600px;
//   }
// }

// @media screen and (max-width: 1300px) {
//   .carousel .slide img {
//     max-width: 600px;
//   }
// }

// @media screen and (max-width: 1200px) {
//   .carousel .slide img {
//     max-width: 500px;
//   }
// }

// @media screen and (max-width: 1000px) {
//   .carousel .slide img {
//     max-width: 400px;
//   }
// }

// @media screen and (max-width: 700px) {
//   .carousel .slide img {
//     max-width: 300px;
//   }
// }

// @media screen and (max-width: 300px) {
//   .carousel .slide img {
//     max-width: 200px;
//   }
// }
