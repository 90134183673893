@import 'src/assets/styles/media';

.interviewPanel {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @include desktop-xxl {
    // padding: 0 3.37rem;
  }

  @include desktop-xl {
    // padding: 0 2.5rem;
  }

  @include tablet-xxl {
    justify-content: flex-start;
    height: calc(100% - 1rem);
  }

  @include mobile-xl {
    min-height: calc(var(--vh, 1vh) * 100 - 12.12rem);
    height: calc(100% - 6rem);
    viewport-fit: cover;
  }
}

.interview {
  height: calc(100% - 5rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @include tablet-xxl {
    height: calc(100% - 3.5rem);
    justify-content: flex-start;
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
    padding: 5px 0.5rem 0.5rem 0.5rem;
    margin-bottom: 1rem;
  }

  @include mobile-xl {
    height: calc(100% - 4.15rem);
  }
}

.interviewHeading {
  // min-height: 6rem;
  background: #f9ac5a;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;

  &.interviewRanking {
    // min-height: 10.5rem;
    // @include mobile-xl {
    //   min-height: 8rem;
    // }
  }

  &.introHeading {
    min-height: 4rem;
    margin-top: 2rem;
    color: transparent;

    @include tablet-xxl {
      margin-top: 0rem;
    }
    @include mobile-xl {
      min-height: 2.5rem;
      height: 2.5rem;
    }
  }

  @include tablet-xxl {
    margin-top: 0rem;
    // height: 4rem;
    align-items: flex-end;
    box-shadow: 0px -4px 4px rgba(170, 154, 130, 0.25);
    border-radius: 10px 10px 0px 0px;
  }
  @include mobile-xl {
    height: auto;
    // max-height: 4.5rem;
  }
}

.interviewWrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 9rem);
  width: 100%;
  flex-grow: 1;

  // &.interviewRanking {
  //   height: calc(100% - 10.5rem);
  //   @include mobile-xl {
  //     height: calc(100% - 8rem);
  //   }
  // }
  // @include mobile-xl {
  //   height: calc(100% - 6rem);
  // }

  &.introBody {
    height: calc(100% - 4rem);
    @include mobile-xl {
      height: calc(100% - 2.5rem);
    }
  }

  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(170, 154, 130, 0.8);
  border-radius: 0px 0px 10px 0px;
  z-index: 0;

  @include tablet-xxl {
    box-shadow: none;
  }
}

.interviewPageContainer {
  display: flex;
  flex-direction: column;
  height: calc(100% - 1rem);
  background: url(../../assets/nImages/bg/840x430.png) repeat;
  background-size: cover;
  margin: 0.5rem;
  @include mobile-xl {
    margin: 0;
    // height: 100%;
  }
}

.physicalExamInterview {
  .interviewPages {
    border: 0;
    padding: 0.5rem 0;
  }
}

.otherInterview {
  padding: 0.75rem;
  @include tablet-xxl {
    padding: 0rem;
    height: calc(100% - 0.5rem);
  }

  .interviewPages {
    padding: 0.75rem 1.5rem 1rem 1.5rem;
    border: 3.5px solid var(--color-highlightGreen);

    @include tablet-xxl {
      border: none;
    }

    @include mobile-xl {
      padding: 0.75rem;
    }
  }
}

.interviewPages {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  .interviewPageContainer {
    &.skip_chapter_active {
      height: calc(100% - 3rem);
      overflow-y: auto;
    }
  }
}

.interview__shift {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  // margin-bottom: 5px;
  // height: 5rem;

  @include mobile-xl {
    align-items: flex-start;
    height: 3rem;
  }
}

.interview_action_group {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 100%;
}

.interview__modify {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100%;

  @include mobile-xl {
    align-items: flex-start;
  }
}

.modifyButton {
  margin-right: 2rem;
}

.interview_skip_holder {
  display: flex;
  justify-content: center;
  padding-right: 0.5rem;
  margin-top: 1.25rem;
  height: 3rem;
  align-items: center;
  width: 100%;
  @include mobile-xl {
    margin-top: 0.5rem;
  }
  &.skip_chapter_active {
    .skip_checkbox {
      background-color: var(--color-darkGreen);
    }
  }
  .skip_checkbox {
    cursor: pointer;
    width: 1.68rem;
    height: 1.5rem;
    border: 1px solid var(--color-darkGreen);
    border-radius: 1px;
    flex-shrink: 0;
    @include mobile-xl {
      height: 1.35rem;
      width: 1.35rem;
    }
  }
  @include mobile-xl {
    .skip_text {
      word-break: break-all;
    }
  }
  .skip_text {
    cursor: pointer;
    padding-left: 0.7rem;
    height: 1.5rem;
    font-size: 1.125rem;
    word-break: break-word;
    padding-top: 0.15rem;
    @include mobile-xl {
      font-size: 0.89rem;
      padding-top: 0.1rem;
    }
  }
}