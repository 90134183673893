@import 'src/assets/styles/media';
.anamnesis {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.anamesis_wrapper {
  display: flex;
  height: 100%;
  .anamesis_chatBox {
    flex: 50%;
    width: 100%;
    background: var(--color-linearGreen);
    border: 1px solid var(--color-scrollBar);
    border-radius: 4px;
    padding: 0.9rem;
    @include mobile-xl{
      padding: 0.25rem;
    }
    .anamesis_chatBox_holder {
      height: 100%;
      overflow-y: auto;
      .noSelectedChat{
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .anamnesis__list {
    height: 100%;
    margin: 0;
    padding: 0.9rem;
    flex: 50%;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    @include mobile-xl{
      padding: 0.25rem;
    }
  }
}
