@import "../../assets/styles/media";

.stars {
  display: flex;
  width: 100%;
  max-width: 35rem;
  justify-content: space-between;
  @include mobile-xl{
    width: 100%;
  }
}

.star {
  width: 8rem;
  height: 6rem;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
  
  @include desktop-xxxl {
    width: 9rem;
    height: 9rem;
  }

  @include tablet-xxl {
    width: 6rem;
    height: 6rem;
  }

  @include mobile-xl {
    width: 3.75rem;
    height: 3.75rem;
  }
}

.star__svg {
  width: 100%;
  height: 100%;
}
