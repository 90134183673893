@import 'src/assets/styles/media';

.welcome {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  color: var(--color-fontBlack);
  align-items: center;

  @include mobile-xl {
    padding: 0;
    align-items: stretch;
  }

  .welcomeResetHolder {
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
    .welcomeResetButton {
      cursor: pointer;
      font-size: 1rem;
      font-weight: 600;
    }
  }
}

.welcome_container {
  width: 75rem;

  @include mobile-xl {
    width: 100%;
  }
}

.welcome__logo {
  margin: 0 auto 1.62rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;

  @include mobile-xl {
    width: 100%;
    height: auto;
    margin: 0;
  }
}

.welcome_bi_logos {
  display: flex;
  width: 17rem;
  align-items: center;
  justify-content: flex-end;
  object-position: center;
  .welcome__img {
    max-width: 100%;
    width: auto;
  }
  @include mobile-xl {
    width: 10.5rem;
    .welcome__img {
      height: auto;
      max-height: 4rem;
      // width: 6.5rem;
    }
  }
}

.welcome__img {
  width: 8rem;
  height: 5rem;
  object-position: center;
  @include mobile-xl {
    width: 6rem;
    height: 4rem;
    padding-left: 4px;
  }
}

.welcome__inner {
  margin-top: 1.5rem;
  text-align: center;
  border-radius: 10px 10px 0px 0px;

  @include mobile-xl {
    height: calc(100% - 7.5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0rem;
    width: 100%;
  }
}

.welcome__title {
  background: #f9ac5a;
  color: var(--color-white);
  border-radius: 10px 10px 0px 0px;
  height: 10rem;
  justify-content: center;
  vertical-align: middle;
  padding: 1.75rem 0;
  word-spacing: 3px;
  position: relative;
  display: flex;
  flex-direction: column;

  @include mobile-xl {
    height: 13rem;
    padding: 1rem;
  }
  @include tablet-xxl {
    width: 100%;
  }
}

.welcome__subtitle {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 3rem;

  @include mobile-xl {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
}

.wc_title_bubble {
  position: absolute;
  left: 1rem;
  bottom: 0;
  height: 50%;
  @include mobile-xl {
    display: none;
  }
}

.welcome_inner_container {
  background: url('../../assets/nImages/bg/840x430.png');
  filter: drop-shadow(0px 4px 25px rgba(170, 154, 130, 0.8));
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 0px 10px 10px;
  height: 27rem;
  padding: 1.5rem;
  @include mobile-xl {
    height: calc(100% - 15rem);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.welcome__inner_bottom {
  height: 24rem;
  border: 5px solid var(--color-highlightGreen);
  padding: 1rem 2rem;
  font-size: 1.3rem;
  font-weight: 500;
  font-style: normal;
  line-height: 1.75rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  width: 100%;
  @include mobile-xl {
    height: 100%;
    border: none;
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 0.5rem;
    align-items: flex-start;
  }
}

.welcome__case_info {
  margin: 1.5rem 0;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  .welcome_parent_info {
    font-size: 1.75rem;
    line-height: 2.5rem;
  }
}

@include mobile-xl {
  .welcome__case_info {
    font-size: 1rem;
    line-height: 1.5rem;
    .welcome_parent_info {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
  .welcome_event_info {
    .welcome_parent_info {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .welcome_sub_info {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}

.welcome_event_info {
  margin: 2rem 0;
  font-size: 1.25rem;
  line-height: 1.75rem;
  .welcome_parent_info {
    font-weight: 700;
  }
  .welcome_sub_info {
    font-weight: 500;
  }
}

.grayField {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notFound {
  width: max-content;
  padding: 1.5rem;
  border-radius: 0.375rem;
  font-weight: 700;
  font-size: 1.125rem;
  color: var(--color-red);
}

.welcome_footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.welcome_custom_inner {
  display: flex;
  max-width: 70%;
  height: auto;
  align-items: center;
  @include tablet-xl {
    max-width: 85%;
  }
  @include mobile-xl {
    max-width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.disclaimer_footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  .disclaimer_back_button {
    background-color: grey;
  }
}

.TermsField {
  display: flex;
  justify-content: center;
  // align-items: center;
  font-size: 1.25rem;
  line-height: 2rem;
  padding-top: 1rem;
  padding: 2rem;
  text-align: justify;

  & > div:first-child {
    cursor: pointer;
  }

  @include tablet-xl {
    max-width: 95%;
    padding-top: 0rem;
  }
  @include mobile-xl {
    height: 100%;
    overflow-y: auto;
    line-height: 1.75rem;
    text-align: justify;
    padding: 0.25rem 1rem;
  }
}

.link {
  color: var(--color-link);
  cursor: pointer;
}