@import "src/assets/styles/media";

.patientCases {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .pc_header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 1rem 1rem 1rem;

    .pc_header_icon {
      width: 8rem;
      height: 3rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
    @include mobile-xl{
      justify-content: space-between;
      padding: 5px 0;
    }
  }
  .pc_body{
    display: flex;
    flex-direction: column;
    height: calc(100% - 5rem);
    @include mobile-xl{
      height: calc(100% - 1rem);
    }
    width: 100%;
    .pc_body_header{
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 5.5rem;
      @include mobile-xl{
        height: 3rem;
      }
      .pc_header_icon{
        width: 18rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 50%;
          height: 5rem;
        }
      }
      .pc_header_title{
        display: flex;
        flex-grow: 1;
        background-color: #F9AC5A;
        border-radius: 10px 10px 0 0;
        font-size: 1.75rem;
        line-height: 2rem;
        color: white;
        font-weight: 700;
        align-items: center;
        padding-left: 1rem;
        @include mobile-xl{
          font-size: 1.35rem;
          line-height: 1.5rem;
          justify-content: center;
        }
      }
    }
    .pc_body_container{
      display: flex;
      height: calc(100% - 5.5rem);
      flex-direction: row;
      flex-grow: 1;
      width: 100%;
      background-color: white;
      border-radius: 10px 0 10px 10px;
      @include mobile-xl{
        border-radius: 0 0 10px 10px;
        padding: 0.5rem;
        box-shadow: 0px 4px 25px rgba(170, 154, 130, 0.8);
      }
      .pc_list{
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        background: url(../../assets/nImages/paper_texture.svg) repeat;
        background-size: cover;
        padding: 1rem;
        filter: drop-shadow(0px 4px 25px rgba(170, 154, 130, 0.8));
        border-radius: 0px 0px 10px 10px;
        gap: 1rem;
        overflow-y: auto;
        @include mobile-xl{
          flex-direction: column;
          background: none;
          filter: none;
        }
      }
      .pc_item{
        border: 3px solid #4FB1C2;
        padding: 1rem 1.5rem;
        flex: 1;
        min-width: 23rem;
        @include mobile-xl{
          border: none;
          border-bottom: 3px solid #4FB1C2;
          padding: 5px;
          padding-bottom: 1rem;
          &:last-child{
            border: 0;
          }
        }
      }
    } 
  }
}