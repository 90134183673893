@import 'src/assets/styles/media';
.comment {
  margin-top: 0.3rem;
}
.commetHeader{
  color: var(--color-darkGreen);
}
.questionSet1Summary {
  height: 100%;
  overflow-y: auto;
}

.questionSet1Summary__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.questionSet1List {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  color: var(--color-fontBlack);
  font-weight: 700;
  line-height: 1.5rem;
  list-style: none;
  border-left: 2px solid var(--color-darkGreen);
  margin: 1rem 0.5rem;
  padding: 0.5rem 1.25rem;
  background-color: var(--color-scrollGrayBackground);
  @include mobile-xl{
    font-size: 0.85rem;
    line-height: 1rem;
  }
}
